<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submit"> Создать </el-button>
    </div>
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" @input="handleNameInput"></el-input>
      </el-form-item>
      <el-form-item label="Название в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { transliterate } from '@/helpers/slug'
import SeoBlock from '@/components/moleculs/SEO.vue'
import { SEO_TYPES } from '@/constants/seo'

export default {
  components: { SeoBlock },
  SEO_TYPES,
  mixins: [notifications],
  data() {
    return {
      form: {
        name: '',
        slug: '',
        setDefault: false,
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  methods: {
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.AddeventServiceCore.ArticleCategoriesActions.create({
            ...this.form,
            setDefault: this.form.setDefault,
            seo: this.form.setDefault
              ? null
              : {
                  ...this.form.seo,
                  setDefault: this.form?.seo?.setDefault,
                  ogImage: {
                    ...this.form?.seo?.ogImage,
                    caption: this.form?.seo?.title,
                  },
                },
          })

        loading.close()

        if (error)
          return this.showNotification(
            'Ошибка создания категории статей',
            'error',
          )

        this.showNotification('Категория статей успешно создана', 'success')
        this.$router.push(ADDEVENT_ADMIN_ROUTES.BLOG.CATEGORIES.LIST)
      })
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    margin-bottom: 1rem;
  }

  .form {
    & > div > label {
      text-align: left;
    }

    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
